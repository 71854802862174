<template>
  <div v-if="is404" style="text-align: center; height: 100vh">
    <span
      style="
        width: auto;
        height: auto;
        display: inline-block;
        vertical-align: middle;
      "
    >
      <h1>404</h1>
      <h3>您要访问的页面去火星了~！</h3>
      <router-link :to="`/`">返回首页 </router-link>
    </span>
    <span
      style="
        height: 100%;
        width: 0;
        display: inline-block;
        vertical-align: middle;
      "
    >
    </span>
  </div>
</template>
<script>
export default {
  name: "Error",
  created() {
    this.tryInitState();
  },
  data() {
    return {
      is404: false,
    };
  },
  methods: {
    tryInitState() {
      const matched = this.$route.matched;
      if (matched && matched.length == 1 && matched[0].path === "*") {
        this.is404 = true;
      }
    },
  },
};
</script>

<style scoped></style>
